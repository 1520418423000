<template>
  <div class="znzz">
    <div class="header">
      <div class="zhuti">
        <div class="h1">商城系统</div>
        <div class="h2">
          <p>以标识码为载体+小程序、公众号、微信群、自建商城等，建立</p>
          <p> 企业或品牌自有的、可以反复利用，又能开展个性化运营的用</p>
          <p> 户资产，可实现从触达-互动-成交的全链路数字化闭环。</p>
        </div>
      </div>
    </div>
    <div class="zhuti">
      <div class="tabs flex">
        <div class="tablist flex-1" @click="tabchoose(li,index)" v-for="(li,index) in tabs" :key="index"><span :class="{'isactive':number==index}">{{li.name}}</span></div>
      </div>
    </div>
    <div class="titles pad56" id="m1">产品功能</div>
    <div class="cj">
      <div class="zhuti">
        <div class="cjlist "  v-for="(li,index) in cpgnList" :key="index">
          <div class="mag32">
            <div class="name">{{li.title}}</div>
            <div class="desc">{{li.titleSub}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="titles" id="m2">商城系统十大优势</div>
    <div class="bg3">
      <div class="zhuti">
        <div style="width: 754px;height: 754px;margin: 0 auto">
          <el-image :src="datas.sdys.image"></el-image>
        </div>
      </div>
    </div>
    <div class="bg">
      <div class="zhuti">
        <div class="titles" id="m3">行业痛点</div>
        <div class="zhuti allfangan">
          <div class="fangan" v-for="(li,index) in hytdList" :key="index">
            <div class="image"><el-image  :src="li.image"></el-image></div>
            <div>
              <div class="title text-overflow">{{li.title}}</div>
              <div style="color: #999;" >{{li.titleSub}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg3">
      <div class="zhuti allfangan">
        <div class="titles" id="m4">应用场景</div>
        <div class="fangan" v-for="(li,index) in yycjList" :key="index">
          <div class="image"><el-image :src="li.image"></el-image></div>
          <div>
            <div class="title text-overflow">{{li.title}}</div>
            <div class="h2 ellipsis2" >{{li.titleSub}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg4">
      <div class="titles">需求咨询留言</div>
      <div class="advice">
          <Advice></Advice>
      </div>
    </div>
    <div class="zhuti">
      <div class="more">
        <div class="titles" id="m5">第三方推荐</div>
        <MoreList :moreList="moreList"></MoreList>
      </div>
    </div>
  </div>
</template>

<script>
import {privatemallindex} from "../../request/moudle/znzz";
import Advice from "../common/advice.vue";
import MoreList from "../common/moreList.vue";
export default {
  name: "",
  components:{
    Advice,
    MoreList
  },
  data(){
    return  {
      number:0,
      datas:'',
      tabs:[{name:'产品功能',id:'m1'},{name:'商城系统十大优势',id:'m2'},{name:'行业痛点',id:'m3'},{name:'应用场景',id:'m4'},{name:'第三方推荐',id:'m5'}],
      cpgnList:[],
      yycjList:[],
      moreList:[],
      hytdList:[]
    }
  },
  created() {
    this.loadconfig()
  },
  methods:{
    scrollToAnchor(anchor) {
      this.$nextTick(() => {
        const anchorElement = document.getElementById(anchor);
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    tabchoose(li,index){
      this.number=index
      this.scrollToAnchor(li.id)
    },
    loadconfig(){
      this.$api.znzz.privatemallindex().then((res)=>{
        this.datas=res.data
        this.cpgnList=res.data.cpgnList
        this.yycjList=res.data.yycjList
        this.hytdList=res.data.hytdList
        this.moreList=res.data.moreList
      })
    },
    remove(e){
      let hdimage=this.$refs.hdimage
      for (const val of hdimage){
        val.className='hdimage display'
      }
      e.target.className='hdimage remove-class show'
    },
  
     //推荐
     tuijian(item){
       if(item.title == "商城管理"){
          window.open('http://shopcmer.bs-icloud.com:8000/login?redirect=%2Fdashboard','_blank')
       }else if(item.title == "仓库管理"){
          window.open('http://admin.czicloud-sy.cn:8000/#/bsLogin','_blank')
       }else if(item.title == "标识解析"){
          window.open('https://www.cz-icloud.com/trial','_blank')
       }else if(item.title == "设备管理"){
          // window.open(config.adminhref+'/#/myiframe/urlPath?','_blank')
       }
    }
  }
}
</script>

<style lang="scss" scoped>
.znzz{
  background: #fff;
}
.mag32{
  margin: 24px;
}
.header{
  height: 230px;
  background: url("../../assets/banner/sysc.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  color: #fff;
  .h1{
    font-size: 42px;
    margin-top: 25px;
  }
  .h2{
    font-size: 20px;
    line-height: 30px;
    margin-top: 15px;
  }
}
.tabs{
  overflow: hidden;
  border-bottom: 1px solid #eee;
  .tablist{
    text-align: center;
    cursor: pointer;
  }
  span{
    display: inline-block;
    padding: 15px;
  }
  .isactive{
    border-bottom: 2px solid #0c8aff;
  }
}
.more{
  overflow: hidden;
  padding: 56px;
  .mlist{
    text-align: center;
    float: left;
    width: 25%;
  }
  .minage{
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 15px;
  }
}
.bg{
  background: #F4F9FE;
  padding:56px;
}
.bg3{
  background: #F8FBFF;
  padding: 56px 0;
}
.bg4{
  background: #FFF;
  padding: 56px 0 0 0;
  .advice {
   width: 1120px;
   height: 80px;
   padding: 50px 40px;
   margin: 0 auto 30px auto;
   border-radius: 6;
   background: #fff;
   box-shadow: 1px 1px 5px rgba(0,0,0,.1);
  }
}
.allfangan{
  overflow: hidden;
  .fangan{
    float: left;
    width: 384px;
    margin: 8px;
    .image{
      width: 100%;
      height: 220px;
      margin-right: 32px;
    }
    .title{
      color: #1D1D1D;
      font-weight: bolder;
      font-size: 16px;
      margin: 15px 0;
    }
    .h2{
      height: 37px;
      color: #999;
    }
  }
}

.bg1{
  background: url("../../assets/znzz/bg1.png") no-repeat;
  background-size: 100% 100%;
  height: 500px;
}

.cj{
  overflow: hidden;
  margin-bottom: 56px;
  .cjlist{
    width: 384px;
    height: 166px;
    background: url("../../assets/znzz/cjlist.png") no-repeat;
    background-size: 100% 100%;
    float: left;
    position: relative;
    margin: 8px;
  }
  .name{
    font-size: 18px;
    font-weight: bolder;
    color: #1D1D1D;
    margin-bottom: 12px;
  }
  .desc{
    color: #999;
  }
}
.zhuti{
  width: 1200px;
  margin: 0px auto;
}
.titles{
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
  padding-bottom: 56px;
  color: #1D1D1D;
}
.pad56{
  padding-top: 56px;
}
</style>